import React from "react";
const About = () => {
   return (
      <div id="about">
         <div className="container">
            <div className="main">
               <h5>
                  Общественный Фонд “Благотворительный фонд поддержки
                  детей-инвалидов “Балам-ай”
               </h5>
            </div>
            <div className="title">
               <h3 id="title">О фонде</h3>
            </div>
            <div className="about">
               <div className="text">
                  <h4>
                     Наша организация одна из первых неправительственных
                     организаций в городе Астана начала оказывать социальные
                     услуги детям и молодежи с инвалидностью. Для эффективной
                     работы с данной категорией в 2010 году были созданы Центры
                     дневного пребывания «Балам-ай». С 2011 года организация
                     оказывает специальные социальные услуги в рамках
                     государственного социального заказа при поддержке ГУ
                     «Управление занятости и социальной защиты населения г.
                     Астана»
                  </h4>
                  <h4>
                     <span>Миссией</span> нашей организации является
                     формирование навыков самопомощи, всестороннее развитие для
                     реализации равных возможностей в обществе детей с особыми
                     нуждами.
                  </h4>
               </div>
               <div className="img">
                  <img src={`${process.env.PUBLIC_URL}/img/about.jpg`} alt="" />
               </div>
            </div>
            
         </div>
      </div>
   );
};

export default About;
