import React from "react";
const Correction = () => {
   return (
      <div id="correction">
         <div className="container">
            <h3 id="title">Уникальные программы коррекции</h3>
            <div className="correction">
               <p>
                  Не следует, однако, забывать о том, что повышение уровня
                  гражданского сознания представляет собой интересный
                  эксперимент проверки новых предложений. С другой стороны
                  постоянный количественный рост и сфера нашей активности
                  обеспечивает широкому кругу специалистов участие в
                  формировании форм воздействия? Значимость этих проблем
                  настолько очевидна, что начало повседневной работы по
                  формированию позиции напрямую зависит от системы масштабного
                  изменения ряда параметров. Не следует, однако, забывать о том,
                  что социально-экономическое развитие напрямую зависит от новых
                  предложений. <br /></p>
                  <p>Равным образом выбранный нами инновационный путь играет важную
                  роль в формировании дальнейших направлений развития проекта?
                  Таким образом, сложившаяся структура организации создаёт
                  предпосылки качественно новых шагов для модели развития! Не
                  следует, однако, забывать о том, что реализация намеченного
                  плана развития играет важную роль в формировании новых
                  предложений? Значимость этих проблем настолько очевидна, что
                  консультация с профессионалами из IT обеспечивает широкому
                  кругу специалистов участие в формировании позиций, занимаемых
                  участниками в отношении поставленных задач.
                  <br /></p>
                  <p id="with">Дорогие друзья, выбранный нами инновационный путь представляет
                  собой интересный эксперимент проверки форм...
               </p>
               <div className="types">
                  <div className="type">
                     <h3 id="title">Уникальные программы коррекции</h3>
                  </div>
                  <div className="type">
                     <h3 id="title">Психокоррекционные программы</h3>
                  </div>
                  <div className="type">
                     <h3 id="title">Логопедические программы</h3>
                  </div>
                  <div className="type">
                     <h3 id="title">Когнитивно-поведенческая программа</h3>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Correction;
